<template>
  <van-swipe class="my-swiper" :autoplay="3000" indicator-color="#00C4C9">
    <van-swipe-item>1</van-swipe-item>
    <van-swipe-item>2</van-swipe-item>
    <van-swipe-item>3</van-swipe-item>
    <van-swipe-item>4</van-swipe-item>
  </van-swipe>
</template>
<script>
export default {
  name: 'banner',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() { }
}
</script>
<style scoped>
.my-swiper {
  height: 115px;
  border-radius: 10px;
  background-color: #F5F5F5;
}
</style>