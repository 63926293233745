<template>
  <div class="groupDetail-box">
    <banner></banner>
    <div class="title">
      标题文字
    </div>
    <div class="content">
      “会展经济发展论坛’自2017年创办至今，已在贵阳成功举办了四届。先后有20余个国家和地区的数十位政府前政要、外使节，200余位业界外宾；29个省市、区的80余个城市的会展界官员、学者、•企业家参与，得到与会者和业界的广泛认可。
    </div>
    <!-- 联系人 -->
    <commonContactItem v-for="(item, index) in 3" :key="index" customClass="pt-10 px-10 pb-20 mt-10"></commonContactItem>
    <div class="title">
      标题文字
    </div>
    <img class="img" src="" alt="">
    <div class="content">
      为落实贵州会展业创新发展工程等政策，同时让外界进一步了解贵州、了解贵州会展业，发挥会展业与各行业深度融合服务招商引资和经贸合作等功能，搭建我省会展业与国内外交流、合作平台，，探索“四季会展大省”友展之路，贵州省省贸促会(博览局) 贵阳市人民政府、贵州商学院将于⑤023年5月g二保白率於议”创新。嵩质量，“会晨”为主题的“第十二届中国（贵州）国际酒类博览会第五届会展经济发展论坛”，与北界同行共活末来，，寻找会展创新和高质量发展路径。
    </div>
  </div>
</template>
<script>
import banner from './components/banner.vue'
import commonContactItem from '../../components/commonContactItem.vue'
export default {
  name:'',
  props:{},
  components:{banner,commonContactItem},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style scoped>
.groupDetail-box {
  padding: 6px 12px 20px;
  background-color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
}
.groupDetail-box .title {
  font-size: 15px;
  color: #262626;
  font-weight: 700;
  padding: 11px 0 6px;
}
.groupDetail-box .content {
  font-size: 15px;
  color: #262626;
  line-height: 25px;
}
.groupDetail-box .img {
  display: block;
  height: 170px;
  margin-bottom: 12px;
  background-color: #F5F5F5;
}
</style>